import React, { useContext, useEffect, useState, useRef } from "react";
import SafeSiteImage from "./SafeSiteImage";
import { QuoteBasketContext } from "../context/QuoteBasketContext";
import { useStaticQuery, graphql, navigate, Link } from "gatsby";
import axios from "axios";
import styled, { css } from "styled-components";
import Grid from "../components/Grid";
import { FormField } from "./Form";
import Tooltippy from "./Tooltippy";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { getGAClientId } from '../utils/getGAClientId';
import randomstring from "randomstring";

const StyledQuoteBasket = styled.div`
  ${({ ...props }) => css`
    background-color: rgb(8 8 8 / 84%);
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 999999999999999;
    overflow: auto;

    /* Datepicker */

    .rdp-root {
      background: #fff;
      border-radius: 4px;
      padding: 16px;
      margin: 0 0 16px 0;
      display: flex;

      --rdp-accent-color: ${props.theme.colors.primary};

      .rdp-chevron {
        fill: currentColor
      }

      .rdp-button_next,
      .rdp-button_previous {
        border-radius: 50%;
        border: 1px solid #fff;

        &:hover {
          color: ${props.theme.colors.primary};
          border-color: currentColor;
        }
      }

      .rdp-day_button {
        border: 1px solid #fff;

        &:hover {
          color: ${props.theme.colors.primary};
          border-color: currentColor;
        }
      }

      .rdp-today .rdp-day_button {
        color: ${props.theme.colors.darkGrey};
        font-weight: bold
      }

      .rdp-selected .rdp-day_button {
        background-color: ${props.theme.colors.primary};
        color: #fff;
      }
    }

    @media (min-width: ${props.theme.breakpoints.md}) {
      z-index: 100;
    }
  `}
`;
const StyledQuoteContainer = styled.div`
  ${({ ...props }) => css`
    background-color: #fff;
    overflow: hidden;
    border-radius: 4px;
    max-width: 960px;
    margin: 0 auto;
  `}
`;
const StyledQuoteHeader = styled.div`
  ${({ ...props }) => css`
    background-color: ${props.theme.colors.primary};
    color: #fff;
    padding: 24px;
    position: relative;
    padding-right: 70px;
  `}
`;
const StyledCloseButton = styled.button`
  ${({ ...props }) => css`
    position: absolute;
    right: 24px;
    top: 24px;
    display: flex;
    align-items: center;
    gap: 8px;

    @media (max-width: 767px) {
      padding-right: 0;
      span {
        display: none;
      }
    }
  `}
`;
const StyledQuoteBody = styled.div`
  ${({ ...props }) => css`
    padding: 24px;
  `}
`;
const StyledQuoteItem = styled.li`
  ${({ ...props }) => css`
    padding: 24px;
    overflow: hidden;
    border-radius: 4px;
    background-color: ${props.theme.colors.lightGrey};
    display: flex;
    gap: 24px;
    margin-top: 16px;
    position: relative;

    @media (max-width: 991px) {
      h3 {
        padding-right: 100px;
      }
    }
  `}
`;
const StyledDivider = styled.div`
  ${({ ...props }) => css`
    height: 1px;
    width: 100%;
    background-color: ${props.theme.colors.lightGrey};
    margin: 32px 0;
  `}
`;
const StyledImage = styled.div`
  ${({ ...props }) => css`
    img {
      max-width: 200px;
      height: auto;
    }
  `}
`;
const FileUploadStyles = styled.div`
  ${({ ...props }) => css`
    padding: 8px 0 24px 0;
    margin-top: 16px;
    label {
      cursor: pointer;
      strong {
        text-decoration: underline;
      }
    }
    input {
      border: 0 !important;
      clip: rect(1px, 1px, 1px, 1px) !important; /* 1 */
      -webkit-clip-path: inset(50%) !important;
      clip-path: inset(50%) !important; /* 2 */
      height: 1px !important;
      margin: -1px !important;
      overflow: hidden !important;
      padding: 0 !important;
      position: absolute !important;
      width: 1px !important;
      white-space: nowrap !important;
    }
  `}
`;
const ButtonStyles = styled.button`
  ${({ ...props }) => css`
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
      0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
    min-width: 200px;
    @media (min-width: ${props.theme.breakpoints.md}) {
    }
  `}
`;
const FormFooterStyles = styled.div`
  ${({ ...props }) => css`
    padding-bottom: 100px;
    text-align: center;

    button {
      margin-bottom: 24px;
    }

    .right_content_wrapper {
      display: flex;
      flex-flow: column-reverse;
    }

    .prices_may_vary {
      text-align: center;
    }

    @media (min-width: ${props.theme.breakpoints.md}) {
      padding-bottom: 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 24px;

      button {
        margin-bottom: 0;
      }

      .right_content_wrapper {
        flex-flow: row;
        gap: 1rem;
        align-items: center;
      }

      .prices_may_vary {
        max-width: 10rem;
        text-align: left;
      }
    }
  `}
`;
const StyledImageLink = styled(Link)`
  ${({ ...props }) => css`
    display: none;
    @media (min-width: ${props.theme.breakpoints.md}) {
      display: block;
    }
  `}
`;
const StyledContent = styled.div`
  ${({ ...props }) => css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `}
`;
const StyledControls = styled.div`
  ${({ ...props }) => css`
    margin-top: 24px;
    display: flex;
    gap: 16px;
    flex-direction: column;

    @media (min-width: ${props.theme.breakpoints.md}) {
      gap: 32px;
      flex-direction: row;
      align-items: flex-end;
      justify-content: flex-start;
    }
  `}
`;
const StyledQuantity = styled.div`
  ${({ ...props }) => css`
    display: flex;
    gap: 16px;
    align-items: flex-end;
  `}
`;
const StyledAmount = styled.div`
  ${({ ...props }) => css`
    max-width: 80px;
    label {
      margin-bottom: 8px;
      display: block;
      font-weight: bold;
    }
    input {
      padding-right: 0;
    }
  `}
`;
const StyledAmountType = styled.div`
  ${({ ...props }) => css`
    min-width: 120px;
  `}
`;
const StyledBottomControls = styled.div`
  ${({ ...props }) => css`
    display: flex;
    gap: 16px;
    justify-content: space-between;
    align-items: flex-end;

    @media (min-width: ${props.theme.breakpoints.md}) {
      gap: 32px;
      justify-content: flex-start;
    }
  `}
`;
const StyledSwitch = styled.div`
  ${({ ...props }) => css`
    display: flex;
    input {
      display: none;
    }

    label {
      display: inline-block;
      background-color: #fff;
      padding: 8px;
      transition: all 0.1s ease-in-out;
      min-width: 80px;
      line-height: 44px;
      background: #fff;
      font-size: 1rem;
      padding: 0 16px;
      font-family: ${props.theme.fontFamily};
      border-left: solid 1px ${props.theme.colors.lightGrey};
      border-right: solid 1px ${props.theme.colors.lightGrey};
      border-top: solid 1px #fff;
      border-bottom: solid 1px #fff;
      -webkit-transition: all ease 0.3s;
      transition: all ease 0.3s;
      text-align: center;

      @media (max-width: 575px) {
        line-height: 22px;
        display: grid;
        place-items: center;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }

    label:hover {
      cursor: pointer;
      border-color: ${props.theme.colors.primary};
    }

    input:checked + label {
      background-color: ${props.theme.colors.primary};
      border-color: ${props.theme.colors.primary};
      color: white;
    }

    label:first-of-type {
      border-radius: 4px 0 0 4px;
    }

    label:last-of-type {
      border-radius: 0 4px 4px 0;
    }
  `}
`;
const StyledRemoveButton = styled.button`
  ${({ ...props }) => css`
    background: transparent;
    text-decoration: underline;
    color: ${props.theme.colors.primary};
    display: flex;
    gap: 8px;
    align-items: center;
    border: 2px solid transparent;
    position: absolute;
    right: 24px;

    top: auto;
    bottom: 24px;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: transparent;
      color: ${props.theme.colors.primary};
      border: 2px solid ${props.theme.colors.primary};
    }

    @media (max-width: 991px) {
      padding-right: 0;
      padding-left: 0;
      bottom: auto;
      top: 16px;

      &:hover {
        background: transparent;
        color: ${props.theme.colors.primary};
        border: 2px solid transparent;
      }
    }
  `}
`;
const StyledQuoteOptions = styled.div`
  ${({ ...props }) => css`
    padding: 24px;
    border-radius: 4px;
    background-color: ${props.theme.colors.lightGrey};
    height: 100%;
    position: relative;
    z-index: 2;

    h3 {
      margin-bottom: 32px;
    }
  `}
`;
const StyledQuoteDetails = styled.div`
  ${({ ...props }) => css`
    padding: 24px;
    border-radius: 4px;
    background-color: ${props.theme.colors.lightGrey};
    height: 100%;

    h3 {
      margin-bottom: 32px;
    }
  `}
`;
const StyledCheckboxGroup = styled.div`
  ${({ ...props }) => css`
    margin-bottom: 32px;
    margin-top: 24px;
  `}
`;
const StyledCheckbox = styled.div`
  ${({ ...props }) => css`
    margin-bottom: 16px;

    input {
      position: absolute; /* take it out of document flow */
      opacity: 0; /* hide it */

      & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
      }

      /* Box. */
      & + label:before {
        content: "";
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
        border-radius: 4px;
      }

      /* Box hover */
      &:hover + label:before {
        box-shadow: 0 0 0 2px ${props.theme.colors.primary};
      }

      /* Box focus */
      &:focus + label:before {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
      }

      /* Box checked */
      &:checked + label:before {
        background: ${props.theme.colors.primary};
      }

      /* Disabled state label. */
      &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
      }

      /* Disabled box. */
      &:disabled + label:before {
        box-shadow: none;
        background: #ddd;
      }

      /* Checkmark. Could be replaced with an image */
      &:checked + label:after {
        content: "";
        position: absolute;
        left: 5px;
        top: 9px;
        background: white;
        width: 2px;
        height: 2px;
        box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white,
          4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
        transform: rotate(45deg);
      }
    }
  `}
`;
const StyledCancelButton = styled.button`
  ${({ ...props }) => css`
    background: none;
    border: solid 2px #fff;
    text-decoration: underline;
    color: ${props.theme.colors.primary};
    &:hover {
      background: transparent;
      color: ${props.theme.colors.primary};
      border: 2px solid ${props.theme.colors.primary};
    }
  `}
`;
const StyledHireDuration = styled.div`
  ${({ ...props }) => css`
    min-width: 120px;
    margin-top: 24px;

    label {
      margin-bottom: 8px;
      display: block;
      font-weight: bold;
    }

    input {
      max-width: 145px;
      padding-right: 0;
    }
  `}
`;
const StyledTextAreaField = styled.div`
  ${({ ...props }) => css`
    margin-top: 24px;
    label {
      margin-bottom: 4px;
      display: block;
      font-weight: bold;
    }

    p {
      margin-bottom: 8px;
      font-size: .75rem;
    }

    textarea {
      resize: vertical
    }
  `}
`;
const StyledFieldSet = styled.fieldset`
  ${({ ...props }) => css`
    margin-bottom: 16px;

    legend {
      margin-bottom: 8px;
      display: block;
      font-weight: bold;
    }
  `}
`;

const BasketItem = ({ item }) => {
  const { quoteBasketItems, setQuoteBasketItems, setQuoteBasketIsActive } =
    useContext(QuoteBasketContext);
  const [durationIsActive, setDurationIsActive] = useState(
    (item.buyOrHire === "hire") || (item.buyOrHire === "both")
  );

  useEffect(()=> {
    sessionStorage.setItem("quoteBasketItems", JSON.stringify(quoteBasketItems));
  },[quoteBasketItems]);

  const handleRemoveItem = () => {
    // Filter all items in storage without item ID
    // Set to context
    setQuoteBasketItems((items)=> {
      return items.filter(function (basketItemId) {
        return basketItemId.id !== item.id;
      });
    });
  };

  const handleQuantityChange = (e) => {
    // Set to context
    setQuoteBasketItems((items) => {
      let basketItems = [...items];

      const itemIndex = basketItems.findIndex(
        (basketItem) => basketItem.id === item.id
      );

      basketItems[itemIndex] = {
        ...basketItems[itemIndex],
        quantity: +e.target.value,
      }

      return basketItems;
    });
  };

  const handleQuantityTypeChange = (e) => {
    // Set to context
    setQuoteBasketItems((items) => {
      let basketItems = [...items];

      const itemIndex = basketItems.findIndex(
        (basketItem) => basketItem.id === item.id
      );

      basketItems[itemIndex] = {
        ...basketItems[itemIndex],
        quantityType: e.target.value,
      }

      return basketItems;
    });
  };

  const handleBuyOrHireChange = (e) => {
    // Set to context
    setQuoteBasketItems((items) => {
      let basketItems = [...items];

      const itemIndex = basketItems.findIndex(
        (basketItem) => basketItem.id === item.id
      );

      basketItems[itemIndex].buyOrHire = e.target.value;

      return basketItems;
    });

    if ((e.target.value === "hire") || (e.target.value === "both")) {
      setDurationIsActive(true);
    } else {
      setDurationIsActive(false);
    }
  };

  const handleHireDurationChange = (e) => {
    // Set to context
    setQuoteBasketItems((items) => {
      let basketItems = [...items];

      const itemIndex = basketItems.findIndex(
        (basketItem) => basketItem.id === item.id
      );

      basketItems[itemIndex].hireDuration = +e.target.value;

      return basketItems;
    });
  };

  return (
    <StyledQuoteItem>
      {/* Image */}
      <StyledImageLink to={item.url} onClick={() => setQuoteBasketIsActive(false)}>
        <StyledImage>
          <SafeSiteImage image={item.image} alt={item.name} width="200" height="140" fit="cover" loading="eager" fetchpriority="high" decoding="sync" />
        </StyledImage>
      </StyledImageLink>
      <StyledContent>
        {/* Info */}
        <h3>
          <Link to={item.url} onClick={() => setQuoteBasketIsActive(false)}>
            {item.name}
          </Link>
        </h3>
        <StyledControls>
          {/* Quanity */}
  
          <StyledQuantity>
            <StyledAmount>
              <label htmlFor={`quantity-${item.id}`}>Quantity</label>
              <input
                id={`quantity-${item.id}`}
                type="number"
                value={+item.quantity}
                onChange={(e) => handleQuantityChange(e)}
                min="1"
              />
            </StyledAmount>
            {/* Quanity type */}
            <StyledAmountType>
              <label htmlFor={`quantity-type-${item.id}`} className="sr-only">
                Quantity type
              </label>
              <select
                id={`quantity-type-${item.id}`}
                value={item.quantityType}
                onChange={(e) => handleQuantityTypeChange(e)}
              >
                <option value="unit">Unit</option>
                <option value="meters">Meters</option>
              </select>
            </StyledAmountType>
          </StyledQuantity>
          <StyledBottomControls>
            {/* Buy or hire*/}
            <StyledSwitch>
              <input
                type="radio"
                id={`buy-${item.id}`}
                name={`buyOrHire-${item.id}`}
                value="buy"
                checked={item.buyOrHire === "buy"}
                onChange={(e) => handleBuyOrHireChange(e)}
              />
              <label htmlFor={`buy-${item.id}`}>Buy</label>
              <input
                type="radio"
                id={`hire-${item.id}`}
                name={`buyOrHire-${item.id}`}
                value="hire"
                checked={item.buyOrHire === "hire"}
                onChange={(e) => handleBuyOrHireChange(e)}
              />
              <label htmlFor={`hire-${item.id}`}>Hire</label>
              <input
                type="radio"
                id={`both-${item.id}`}
                name={`buyOrHire-${item.id}`}
                value="both"
                checked={item.buyOrHire === "both"}
                onChange={(e) => handleBuyOrHireChange(e)}
              />
              <label htmlFor={`both-${item.id}`}>Both</label>
            </StyledSwitch>
            {/* Remove item */}
            <StyledRemoveButton onClick={handleRemoveItem}>
              <svg viewBox="0 0 512 512">
                <g>
                  <path
                    d="M478,161.66H34a27.5,27.5,0,0,1,0-55H478a27.5,27.5,0,0,1,0,55Z"
                    fill="currentColor"
                  ></path>
                  <path
                    d="M355.64,145a27.5,27.5,0,0,1-27.5-27.5V80.06c0-13.27-11.77-24.06-26.23-24.06H210.09c-14.46,0-26.23,10.79-26.23,24.06v37.42a27.5,27.5,0,0,1-55,0V80.06C128.86,36.47,165.3,1,210.09,1h91.82c44.79,0,81.23,35.47,81.23,79.06v37.42A27.5,27.5,0,0,1,355.64,145Z"
                    fill="currentColor"
                  ></path>
                </g>
                <path
                  d="M370.25,511H141.75c-40,0-72.5-30.65-72.5-68.32V141.24a27.5,27.5,0,0,1,55,0V442.68c0,7.22,8,13.32,17.5,13.32h228.5c9.49,0,17.5-6.1,17.5-13.32V141.24a27.5,27.5,0,0,1,55,0V442.68C442.75,480.35,410.23,511,370.25,511Z"
                  fill="currentColor"
                ></path>
              </svg>
              Remove
            </StyledRemoveButton>
          </StyledBottomControls>
        </StyledControls>
        {durationIsActive ? (
          <StyledHireDuration>
            <label htmlFor={`hireDuration-${item.id}`}>Hire Duration (days)</label>
            <input
              id={`hireDuration-${item.id}`}
              type="number"
              value={item.hireDuration}
              onChange={(e) => handleHireDurationChange(e)}
              min="1"
            />
          </StyledHireDuration>
        ) : (
          ""
        )}
      </StyledContent>
    </StyledQuoteItem>
  );
};

export const QuoteBasket = () => {
  const {
    quoteBasketItems,
    setQuoteBasketItems,
    quoteBasketIsActive,
    setQuoteBasketIsActive,
  } = useContext(QuoteBasketContext);
  const [filesText, setFilesText] = useState("");
  const [dateSelected, setDateSelected] = useState();
  const [exactDateIsActive, setExactDateIsActive] = useState(false);
  const form = useRef()
  const [formData, setFormData] = useState({
    companyPrivate: "company",
    preferredDeliveryDate: "preferredDeliveryNextDay"
  });
  const rand = randomstring.generate(5)

  useEffect(() => {
    const savedFields = sessionStorage.getItem("quoteBasketFields");
    if (savedFields) {
      const parsedData = JSON.parse(savedFields);
      setFormData(parsedData);
      setExactDateIsActive(parsedData?.preferredDeliveryDate === 'preferredDeliveryExactDate');
      parsedData?.date ? setDateSelected(new Date(parsedData.date)) : null;
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("quoteBasketFields", JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    if (formData.preferredDeliveryDate === 'preferredDeliveryExactDate') {
      setExactDateIsActive(true);
    } else {
      setExactDateIsActive(false);
    }
  }, [formData.preferredDeliveryDate])

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Update the specific field that changes
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  let siteData = {
    wp: {
      siteSettings: {
        siteSettings: {
          quoteFormHeading: 'Request a free no-obligation quote',
          quoteFormSubheading: 'We respond in under 30 mins on average (excl. weekends)',
          telephone: ''
        }
      }
    },
    site: {
      siteMetadata: {
        quoteBasketFormEndpoint: ''
      }
    }
  }
  if (!process.env.STORYBOOK) {
    siteData = useStaticQuery(graphql`
      query QuoteBasketQuery {
        wp {
          siteSettings {
            siteSettings {
              quoteFormHeading
              quoteFormSubheading
              telephone
            }
          }
        }
        site {
          siteMetadata {
            quoteBasketFormEndpoint
          }
        }
      }
    `);
  }

  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.current.reset();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const basketItems = quoteBasketItems;

    const basketSummary = `
    ${basketItems.map(
      (item, index) =>
        `${item.name} (${item.quantity} ${item.quantityType}${
          item.quantityType === "unit" && item.quantity !== 1 ? "s" : ""
        } to ${item.buyOrHire === "both" ? "buy or hire" : item.buyOrHire} ${
          item.buyOrHire === "hire" || item.buyOrHire === "both"
            ? `for ${item.hireDuration} day${
                item.hireDuration !== 1 ? "s" : ""
              }`
            : ""
        })`
    )}
    `;

    setServerState({ submitting: true });
    axios({
      method: "post",
      url: siteData.site.siteMetadata.quoteBasketFormEndpoint,
      data: {
        quote_summary: basketSummary,
        ...formData,
        exact_delivery_data: dateSelected ? format(dateSelected, "PP") : "",
      },
      headers: {
        'Content-Type': "multipart/form-data"
      }
    })
      .then((r) => {
        const email = formData.email;
        const phone = formData.tel;
        handleServerResponse(true, "Thanks!", form);
        window.uetq = window.uetq || [];
        window.uetq.push("event", "submit_lead_form", {});

        setQuoteBasketIsActive(false);
        setQuoteBasketItems([]);
        setFilesText("");
        setFormData({
          preferredDeliveryDate: "preferredDeliveryNextDay",
          companyPrivate: "company"
        });
        setExactDateIsActive(false);
        sessionStorage.removeItem("quoteBasketFields");
        sessionStorage.setItem("quoteBasketItems", JSON.stringify([]));
        dataLayer.push({
          event: 'formSubmit',
          formName: 'careerApplicationForm',
          userEmail: email,
          userPhone: phone
        })
        navigate(`/ssf-confirmation-page`);
      })
      .catch((r) => {
        console.error(r)
        handleServerResponse(false, r.response?.data?.error || 'Something went wrong', form);
      });
  };

  const handleFileUploadChange = (e) => {
    const files = e.target.files;
    const numOfFiles = files.length;

    if (numOfFiles > 1) {
      setFilesText(`${numOfFiles} files selected`);
    } else {
      setFilesText(files[0].name);
    }
  };

  const handleClose = () => {
    setQuoteBasketIsActive(false);
    setFilesText("");
  };

  const handleBackgroundClick = (e) => {
    if (e.target.id === `${rand}_quote-background`) {
      handleClose();
    }
  };

  const handleDateChange = (date) => {
    setDateSelected(date)
    setFormData({
      ...formData,
      date
    })
  }

  return (
    <>
      {quoteBasketIsActive && (
        <StyledQuoteBasket
          onClick={(e) => handleBackgroundClick(e)}
          id={`${rand}_quote-background`}
        >
          <StyledQuoteContainer>
            <StyledQuoteHeader>
              <h2>Request a free no-obligation quote</h2>
              <p>We respond in under 30 mins on average (excl. weekends)</p>
              <StyledCloseButton onClick={() => handleClose()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                  <path
                    fill="#fff"
                    d="m3.302 4.717 1.415-1.414 15.98 15.98-1.414 1.414z"
                  />
                  <path
                    fill="#fff"
                    d="m3.303 19.283 15.98-15.98 1.414 1.414-15.98 15.98z"
                  />
                </svg>
                <span>Close</span>
              </StyledCloseButton>
            </StyledQuoteHeader>
            <StyledQuoteBody>
              <h3>
                {quoteBasketItems.length} item
                {quoteBasketItems.length !== 1 && "s"} in your quote
              </h3>

              {/* Basket items */}
              {quoteBasketItems && quoteBasketItems.length > 0 && (
                <ol>
                  {quoteBasketItems.map((item, index) => {
                    return (
                      <BasketItem
                        key={item.id}
                        item={item}
                        closeClick={handleClose}
                      />
                    );
                  })}
                </ol>
              )}

              <StyledDivider />

              {/* Contact details */}
              <div>
                <form onSubmit={handleOnSubmit} onChange={handleFormChange} ref={form}>
                  <input
                    type="hidden"
                    name="googleClientId"
                    id={`${rand}_googleClientId`}
                    value={getGAClientId()}
                    hidden
                  />
                  <Grid rowGap="16px">
                    <Grid item md="6">
                      <StyledQuoteOptions>
                        <h3>Delivery and installation</h3>

                        {/*Prefered delivery dates*/}
                        <StyledFieldSet>
                          <legend>Preferred delivery date</legend>

                          <StyledSwitch>
                            <input
                              type="radio"
                              id={`${rand}_preferredDeliveryNextDay`}
                              name="preferredDeliveryDate"
                              value="preferredDeliveryNextDay"
                              checked={
                                formData.preferredDeliveryDate === "preferredDeliveryNextDay"
                              }
                            />
                            <label htmlFor={`${rand}_preferredDeliveryNextDay`}>
                              Next day
                            </label>
                            <input
                              type="radio"
                              id={`${rand}_preferredDelivery214Days`}
                              name="preferredDeliveryDate"
                              value="preferredDelivery214Days"
                              checked={
                                formData.preferredDeliveryDate === "preferredDelivery214Days"
                              }
                            />
                            <label htmlFor={`${rand}_preferredDelivery214Days`}>
                              2-14 days
                            </label>
                            <input
                              type="radio"
                              id={`${rand}_preferredDeliveryExactDate`}
                              name="preferredDeliveryDate"
                              value="preferredDeliveryExactDate"
                              checked={
                                formData.preferredDeliveryDate === "preferredDeliveryExactDate"
                              }
                            />
                            <label htmlFor={`${rand}_preferredDeliveryExactDate`}>
                              Select exact date
                            </label>
                          </StyledSwitch>
                        </StyledFieldSet>

                        {/* Exact date */}
                        {exactDateIsActive && (
                          <DayPicker
                            mode="single"
                            selected={dateSelected}
                            onSelect={handleDateChange}
                            disabled={{ before: new Date() }}
                          />
                        )}

                        {/* Delivery location */}
                        <FormField>
                          <input
                            type="text"
                            name="deliveryLocation"
                            id={`${rand}_deliveryLocation`}
                            aria-describedby="deliveryLocation"
                            placeholder="Delivery Location"
                            required
                            value={formData.deliveryLocation || ''}
                          />
                          <label htmlFor={`${rand}_deliveryLocation`}>
                            Delivery location
                          </label>
                        </FormField>

                        <StyledCheckboxGroup>
                          <StyledCheckbox>
                            <input
                              type="checkbox"
                              id={`${rand}_quote_for_installation`}
                              name="quote_for_installation"
                              value="yes"
                              checked={formData.quote_for_installation || false}
                            />
                            <label htmlFor={`${rand}_quote_for_installation`}>
                              Quote for installation
                            </label>
                          </StyledCheckbox>
                        </StyledCheckboxGroup>
                      </StyledQuoteOptions>
                    </Grid>
                    <Grid item md="6">
                      <StyledQuoteDetails>
                        <h3>Additional information</h3>

                        <StyledTextAreaField>
                          <label htmlFor={`${rand}_requirements`}>Notes</label>
                          <p>
                            E.g. Accessories required, colour preference, access
                            restrictions
                          </p>
                          <textarea
                            type="text"
                            name="notes"
                            id={`${rand}_requirements`}
                            aria-describedby="notes"
                            placeholder=""
                            rows="3"
                            value={formData.notes || ''}
                          />
                        </StyledTextAreaField>

                        <FileUploadStyles>
                          <Tooltippy text="You can upload an unlimited number of files, but with a maximum combined size of 25mb per form submission.">
                            <label htmlFor={`${rand}_file`}>
                              <strong>Attach files</strong> (optional){" "}
                              {filesText && `- ${filesText}`}
                            </label>
                          </Tooltippy>
                          <input
                            type="file"
                            id={`${rand}_file`}
                            name="files[]"
                            multiple
                            onChange={handleFileUploadChange}
                          />
                        </FileUploadStyles>
                      </StyledQuoteDetails>
                    </Grid>
                  </Grid>
                  <StyledDivider />
                  <StyledQuoteDetails>
                    <h3>Your details</h3>
                    <FormField>
                      <input
                        type="text"
                        name="name"
                        id={`${rand}_name`}
                        required
                        aria-describedby="name"
                        placeholder="Name"
                        value={formData.name || ''}
                      />
                      <label htmlFor={`${rand}_name`}>Name</label>
                    </FormField>
                    <FormField>
                      <input
                        type="email"
                        name="email"
                        id={`${rand}_email`}
                        required
                        aria-describedby="email"
                        placeholder="Email"
                        value={formData.email || ''}
                      />
                      <label htmlFor={`${rand}_email`}>Email</label>
                    </FormField>
                    <FormField>
                      <input
                        type="tel"
                        name="tel"
                        id={`${rand}_tel`}
                        required
                        aria-describedby="tel"
                        placeholder="Phone number"
                        value={formData.tel || ''}
                      />
                      <label htmlFor={`${rand}_tel`}>Phone number</label>
                    </FormField>
                    <StyledSwitch style={{marginBottom: '16px'}}>
                      <input
                        type="radio"
                        id={`${rand}_company`}
                        name="companyPrivate"
                        value="company"
                        checked={
                          formData.companyPrivate === "company"
                        }
                      />
                      <label htmlFor={`${rand}_company`}>Company</label>
                      <input
                        type="radio"
                        id={`${rand}_private`}
                        name="companyPrivate"
                        value="private"
                        checked={
                          formData.companyPrivate === "private"
                        }
                      />
                      <label htmlFor={`${rand}_private`}>Private</label>
                    </StyledSwitch>
                    {formData.companyPrivate === "company" && (
                      <FormField>
                        <input
                          type="text"
                          name="company"
                          id={`${rand}_company`}
                          aria-describedby={`${rand}_company`}
                          placeholder="Company Name"
                        />
                        <label htmlFor={`${rand}_company`}>Company Name</label>
                      </FormField>
                    )}
                  </StyledQuoteDetails>

                  <StyledDivider />

                  <FormFooterStyles>
                    <StyledCancelButton onClick={() => handleClose()}>
                      Continue browsing
                    </StyledCancelButton>
                    <div className="right_content_wrapper">
                      <p className="prices_may_vary">Prices vary by quantity and delivery location</p>
                      <ButtonStyles
                        type="submit"
                        disabled={serverState.submitting}
                      >
                        Request quote
                      </ButtonStyles>
                      <p className="no-js">Please enable Javascript to continue</p>
                    </div>
                    {/* {serverState.status && (
                      <p className={!serverState.status.ok ? "errorMsg" : ""}>
                        {serverState.status.msg}
                      </p>
                    )} */}
                  </FormFooterStyles>
                </form>
              </div>
            </StyledQuoteBody>
          </StyledQuoteContainer>
        </StyledQuoteBasket>
      )}
    </>
  );
};
